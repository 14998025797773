import React from "react";
import SOSButton from '../components/sosbutton';
import SOSLinkButton from '../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../logo.png';
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const navigateKontakt = () => {
    navigate('./kontakt');
  };
  const navigateHilfsangebote = () => {
    navigate('./hilfsangebote');
  };
  const navigateAssistenz = () => {
    navigate('./assistenz');
  };
  const navigateAbout = () => {
    navigate('./impressum');
  };

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title">SOS</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <p className="center">Anlaufstelle für Sorgen und Probleme innerhalb der Stipendiat:innenschaft der Friedrich-Naumann-Stiftung für die Freiheit</p>
        <h2>Erst einmal tief durchatmen! Du bist hier genau richtig, wenn du</h2>
        <ul>
          <li>Opfer von Belästigung geworden bist oder einen solchen Fall beobachtet hast.</li>
          <li>Kontakt zu den Organisatoren einer Veranstaltung oder unseren Vertrauenspersonen suchst.</li>
          <li>vertrauensvolle und anonyme Hilfe suchst in Fällen, die den Code of Conduct betreffen.</li>
          <li>nach Informationen über den Code of Conduct suchst</li>
        </ul>

        <h2>Womit können wir dir helfen?</h2>

        <SOSButton title="Ich möchte jemanden kontaktieren." click={navigateKontakt}/>
        {/* <SOSButton title="Ich weiß nicht, was ich tun soll." click={navigateAssistenz}/> */}
        <SOSButton title="Weitere Informationen" click={navigateHilfsangebote}/>
      </div>
      <footer className="SOS-footer">
        <SOSLinkButton title={cookies.bgColor !== 'dark' ? 'Dark-Privacy-Modus' : 'Normaler Modus'} click={() => handleDarkMode()}/><br/>
        <SOSLinkButton title="Cookies & Impressum" click={navigateAbout}/>
        <p className="center">Diese Seite basiert auf der <a href="https://sos.bundes-lhg.de/">Anlaufstelle für Sorgen und Probleme des Bundesverbands der Liberalen Hochschulgruppen</a>. Wir danken dem LHG-Bundesverband für die Nutzungserlaubnis!</p>
      </footer>
    </div>
  );
}
