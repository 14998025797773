import SOSLinkButton from '../components/soslinkbutton';
import SOSButton from '../components/sosbutton';
import { useNavigate } from "react-router";
import logo from '../logo.png';
import { useCookies } from "react-cookie";

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate(-1);
  }
 
  const navigateLogin = () => {
    navigate('../login');
  };

  const navigateImpressum = () => {
    window.location.assign('http://www.stipendiat.org/impressum/');
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Cookies & Impressum</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSButton title="Zum Impressum" click={navigateImpressum}/>
      	<p>Datenschutzhinweis: Um korrekt zu funktionieren setzt diese Seite Cookies ein, mit der Verwendung erklärst du dich damit einverstanden. Keine Sorge, wir tracken dich nicht, sondern speichern lediglich notwendige Einstellungen wie bspw. das Setzen des Dark Modes :)</p>
        <p>Der bewusste Missbrauch dieser Plattform und insbesondere dort angebotener Nothilfedienste wie dem Notruf stellt ein satzungswidriges Verhalten und ggf. sogar eine Straftat dar. Wir behalten uns vor, solche Fälle zu verfolgen.</p>
        <SOSLinkButton title="Zum Login für Accountinhaber" addClass="center" click={navigateLogin}/>
      </div>
      <footer className="SOS-footer">
        <p>Made 2022 with ❤️ by Katharina Lauterbach and Maximilian Wiegand</p>
      </footer>
    </div>
  );
}
