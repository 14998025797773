import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import SOSImg from '../../components/sosimg';
import SOSLinkButton from '../../components/soslinkbutton';
import fragezeichen from '../../icons/fragezeichen.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate(-1);
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const navigateVertrauenspersonen = () => {
    navigate('../../kontakt/vertrauenspersonen');
  };
  const navigateOmbud = () => {
    navigate('../../kontakt/ombudsperson');
  };
  const navigateBundesvorstand = () => {
    navigate('../../kontakt/bundesvorstand');
  };
  const navigateLandesvorstand = () => {
    navigate('../../kontakt/landesvorstand');
  };
  const navigateSchiedsgericht = () => {
    navigate('../../kontakt/bundesschiedsgericht');
  };
  const navigateOrganisatoren = () => {
    navigate('/kontakt/organisatoren')
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>An wen soll ich<br/>mich wenden?</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={fragezeichen} />
        <p>Keine Sorge, wir finden die richtige Ansprechperson für dich.</p>
        <h2>Organisator:innen</h2>
        <p>Wenn du auf einer stipendiatischen Veranstaltungen der FNF organisatorische Hilfe benötigst oder dich verirrt hast, kontaktiere einfach die <b><SOSLinkButton addClass="no-margin" title="Organisator:innen" click={navigateOrganisatoren}/></b>.</p>
        <h2>Vertrauenspersonen</h2>
        <p>Wann immer du dir Sorgen machst, persönlich betroffen bist oder es um das Verhalten von Stipendiat:innen geht, wende dich am Besten vertrauensvoll und gerne auch anonym an unsere <b><SOSLinkButton addClass="no-margin" title="Vertrauenspersonen" click={navigateOmbud}/></b>.</p>
        <h2>Ansprechpartner am Hochschulort (ASP)</h2>
        <p>An deinem Hochschulort unterstützt dich dein <b><SOSLinkButton addClass="no-margin" title="ASP" click={navigateLandesvorstand}/></b> ebenfalls gerne bei Sorgen und Problemen.</p>
        <h2>Sprecher:innen</h2>
        <p>Möchtest du lieber mit einem Mitglied der <b><SOSLinkButton addClass="no-margin" title="Sprecher:innen" click={navigateBundesvorstand}/></b> sprechen? Kein Problem, auch diese sind immer gerne für dich erreichbar.</p>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
