import SOSImgButton from '../components/sosimgbutton';
import SOSLinkButton from '../components/soslinkbutton';
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import logo from '../logo.png';
import { useCookies } from "react-cookie";
import ja from '../icons/ja.png';
import nein from '../icons/nein.png';
import hand from '../icons/hand.png';
import uni from '../icons/uni.png';
import traurig from '../icons/traurig.png';
import sorge from '../icons/sorge.png';
import weinen from '../icons/weinen.png';
import sad from '../icons/sad.png';
import wuetend from '../icons/wütend.png';
import sos from '../icons/sos.png';
import fragezeichen from '../icons/fragezeichen.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const [atEvent, setAtEvent] = useState(false);
  const [meOther, setMeOther] = useState('');
  const [issue, setIssue] = useState('issue_default');

  const [page, setPage] = useState(1);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const restore = params.get('restore');

    if (restore === 'true') {
      var pageNr =  window.localStorage.getItem('page');
      setPage(pageNr);
      if (pageNr === null) {
        setPage(1);
      } else if (pageNr !== 1) {
        setAtEvent(window.localStorage.getItem('atEvent'));
        setMeOther(window.localStorage.getItem('meOther'));
      }
    } else {
      window.localStorage.setItem('page', 1);
      window.localStorage.setItem('atEvent', false);
      window.localStorage.setItem('meOther', '');
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('page', page);
  }, [page]);
  useEffect(() => {
    window.localStorage.setItem('atEvent', atEvent);
  }, [atEvent]);
  useEffect(() => {
    window.localStorage.setItem('meOther', meOther);
  }, [meOther]);

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const switchPageTwo = () => {
    setPage(2);
  }

  const switchPageThree = () => {
    setPage(3);
  }

  useEffect(() => {
    if (issue === 'issue_sos') {
      navigate('./assistenz-notfall');
    } else if (issue === 'issue_harass' && atEvent) {
      navigate('./assistenz-belaestigung-event');
    } else if (issue === 'issue_harass' && !atEvent) {
      navigate('./assistenz-belaestigung-noevent');
    } else if (issue === 'issue_talk') {
      navigate('./assistenz-sprechen');
    } else if (issue === 'issue_dispute' && atEvent) {
      if (meOther === 'me') {
        navigate('./assistenz-konflikt-event-self');
      } else {
        navigate('./assistenz-konflikt-event-other');
      }
    } else if (issue === 'issue_dispute' && !atEvent) {
      if (meOther === 'me') {
        navigate('./assistenz-konflikt-noevent-self');
      } else {
        navigate('./assistenz-konflikt-noevent-other');
      }
    } else if (issue === 'issue_attack' && atEvent) {
      navigate('./assistenz-angriff-event');
    } else if (issue === 'issue_attack' && !atEvent) {
      navigate('./assistenz-angriff-noevent');
    } else if (issue === 'issue_question') {
      navigate('./assistenz-fragen-veranstaltung');
    } else if (issue === 'issue_other' && atEvent) {
      navigate('./assistenz-sonstige-event');
    } else if (issue === 'issue_other' && !atEvent) {
      navigate('./assistenz-sonstige-noevent');
    } else if (issue !== 'issue_default') {
      navigate('./assistenz-sonstige-noevent');
    }

  }, [issue])

  const backButton = () => {
    if (page > 1) {
      setPage(page - 1);
    } else {
      navigate('../');
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={backButton}>&lt;</button>Assistenz</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <p>Keine Sorge, wir werden dir helfen, den richtigen Umgang mit der Situation zu finden.</p>
        <p>Dafür brauchen wir ein paar Infos von dir. Diese Infos werden verschlüsselt erhoben und nirgends gespeichert.</p>
        <div className="SOS-assistant-pages">
          <div className={page === 1 ? '' : 'hide'} id="page1">
            <h1>Bist du gerade auf einer LHG-Veranstaltung?</h1>
            <div className="SOS-content-grid">
              <SOSImgButton title="Ja" imgSrc={ja} click={() => {setAtEvent(true); switchPageTwo()}}/>
              <SOSImgButton title="Nein" imgSrc={nein} click={() => {setAtEvent(false) ; switchPageTwo()}}/>
            </div>
            <p className="center">nur noch 2 Fragen...</p>
          </div>
          <div className={page === 2 ? '' : 'hide'} id="page2">
            <h1>Benötigst du oder jemand anderes Hilfe?</h1>
            <div className="SOS-content-grid">
              <SOSImgButton title="Ich" imgSrc={hand} click={() => {setMeOther('me'); switchPageThree()}}/>
              <SOSImgButton title="Jemand anderes" imgSrc={uni} click={() => {setMeOther('other'); switchPageThree()}}/>
            </div>
            <p className="center">nur noch eine Frage...</p>
          </div>
          <div className={page === 3 ? '' : 'hide'} id="page3">
            <h1>Was ist vorgefallen?</h1>
            <div className="SOS-content-grid">
              {meOther === 'other' ? '' : <SOSImgButton title="Ich habe Probleme mit einem Mitglied" imgSrc={sorge} click={() => setIssue('issue_dispute')}/>}
              {meOther === 'other' ? '' : <SOSImgButton title="Ich wurde belästigt" imgSrc={traurig} click={() => setIssue('issue_harass')}/>}
              {meOther === 'other' ? '' : <SOSImgButton title="Ich wurde bedroht oder angegriffen" imgSrc={weinen} click={() => setIssue('issue_attack')}/>}
              {meOther === 'other' ? '' : <SOSImgButton title="Ich möchte einfach nur reden" imgSrc={sad} click={() => setIssue('issue_talk')}/>}
              {meOther === 'me' ? '' : <SOSImgButton title="Jemand wurde belästigt" imgSrc={traurig} click={() => setIssue('issue_harass')}/>}
              {meOther === 'me' ? '' : <SOSImgButton title="Ich habe einen Konflikt beobachtet" imgSrc={wuetend} click={() => setIssue('issue_dispute')}/>}
              {(meOther === 'me' || !atEvent) ? '' : <SOSImgButton title="Ich beobachte einen Notfall" imgSrc={sos} click={() => setIssue('issue_sos')}/>}
              {!atEvent ? '' : <SOSImgButton title="Fragen zur Veranstaltung" imgSrc={fragezeichen} click={() => setIssue('issue_question')}/>}
              <SOSImgButton title="Etwas anderes" imgSrc={hand} click={() => setIssue('issue_other')}/>
            </div>
            <p className="center">gleich geschafft...</p>
          </div>
        </div>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
