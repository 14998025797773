import SOSButton from '../components/sosbutton';
import SOSLinkButton from '../components/soslinkbutton';
import SOSImgButton from '../components/sosimgbutton';
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import logo from '../logo.png';
import helm from '../icons/helm.png';
import dokument from '../icons/dokument.png';
import sprechen from '../icons/sprechen.png';
import { useCookies } from "react-cookie";

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const navigateBack = () => {
    navigate(-1);
  }
  const navigateAssistenz = () => {
    navigate('../assistenz');
  };

  const navigateOmbud = () => {
    navigate('../kontakt/ombudsperson');
  };
  const navigateCoC = () => {
    navigate('./code-of-conduct');
  };

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Hilfsangebote</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <p>Hier findest du den Code of Conduct der Stiftung zum Nachlesen. Du kannst auch direkt unsere Vertrauenspersonen kontaktieren, gerne auch anonym.</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="Code of Conduct der FNF" imgSrc={helm} click={navigateCoC}/>
          <SOSImgButton title="Kontakt zu den Vertrauenspersonen" imgSrc={sprechen} click={navigateOmbud}/>
        </div>
        <SOSButton title="Nicht das gefunden, was du suchst?" click={navigateAssistenz}/>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
