import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
import logo from '../logo.png';
import SOSButton from '../components/sosbutton';
import SOSLinkButton from '../components/soslinkbutton';
import SOSLargeButton from '../components/soslargebutton';


function App() {
  const navigate = useNavigate();
  const [loginCookie, setLoginCookie, removeLoginCookie] = useCookies(['login_token']);
  const [username, setUsername] = useState('[Benutzer]');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState('loading-square');

  useEffect(() => {
    //go to login page if not logged in
    if (!loginCookie.login_token) {
      navigate('../login');
    } else {
      //get username
      axios.get(window.location.origin + '/db/sos-db-request.php?req_id=username&token=' + loginCookie.login_token) //code to ./
      .then(response => setUsername((response.data)[0][0]))
      .catch( (error) => {
        console.log(error);
      });

      //get events
      axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_events&access_token=' + loginCookie.login_token) //code to ./
      .then(response => {setEvents(response.data); setLoading('');})
      .catch( (error) => {
        console.log(error);
        setLoading('');
      });
    }
  },[]);

  const logout = () => {
    removeLoginCookie('login_token', { path: '/' });
    navigate('../login');
  }

  const navigateAddEvent = () => {
    navigate('../manage/add-event');
  }

  const navigateEditEvent = (eventId) => {
    navigate('../manage/edit-event?event_id=' + eventId);
  }

  return (
    <div className="SOS grey">
      <header className="SOS-header">
        <img className="SOS-header-logo" src={logo} alt="Stip-Logo"/>
        <h1 className="SOS-header-title">{username}</h1>
        <SOSLinkButton addClass="SOS-header-logout-button" title="Logout" click={logout}/>
      </header>
      <div className="SOS-content">
        <h1>Sichtbare Veranstaltungen</h1>
        <p>Veranstaltung werden einen Monat nach dem angegebenen Datum automatisch gelöscht.</p>

        <div className={loading}>
          {events.length > 0 && events.map((event, i) => <SOSLargeButton key={i} title={event[1]} subtitle={moment(event[2]).format('DD.MM.YYYY')} click={() => navigateEditEvent(event[0])}/>)}
          {events.length === 0 ? <p>Keine Veranstaltungen gefunden.</p> : ''}
        </div>
        <SOSButton title="Veranstaltung hinzufügen" click={navigateAddEvent}/>
        <p className="center">Falls ihr Fragen oder Probleme habt, wendet euch gerne an <a href="mailto:sprecher.innen@stipendiat.org?subject=Frage%20oder%20Problem%20zur%20SOS-App&body=%0A%0A*Diese%20E-Mail%20wurde%20von%20der%20Hilfe-Funktion%20der%20SOS-App%20der%20FNF-Stips%20erstellt.*">sprecher.innen@stipendiat.org</a></p>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}

export default App;
