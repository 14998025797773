import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import SOSImg from '../../components/sosimg';
import SOSLinkButton from '../../components/soslinkbutton';
import SOSCollapse from '../../components/soscollapse';
import vielfalt from '../../icons/vielfalt.png';
import respekt from '../../icons/respekt.png';
import umarmung from '../../icons/umarmung.png';
import hand from '../../icons/hand.png';
import schiedsgericht from '../../icons/schiedsgericht.png';
import lavo from '../../icons/lavo.png';
import sprechen from '../../icons/sprechen.png';
import nein from '../../icons/nein.png';
import SOSButton from "../../components/sosbutton";


export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const navigateBack = () => {
    navigate(-1);
  }

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
   });
  }

  const navigateFNFCoC = () => {
    window.location.assign('https://www.freiheit.org/de/code-of-conduct');
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Code of Conduct</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <h2>Code of Conduct für Veranstaltungen der Sprecher:innen</h2>
        <ol>
          <li>Du hast das Recht bei Veranstaltungen anwesend zu sein, solange du dich rücksichtsvoll und im Rahmen des Code of Conducts gegenüber den anderen Teilnehmer:innen, der Seminarleitung und den Referent:innen verhältst.</li>
          <li><b>Nein heißt Nein!</b> In jedem Fall sind die Grenzen von anderen zu akzeptieren und zu respektieren. Wenn du dich in einer Notsituation befindest, suche dir Hilfe bei anderen Seminarteilnehmer:innen, über die Web-App oder bei der Seminarleitung. Du bestimmst, was für dich zu weit geht.</li>
          <li><b>Wir helfen uns gegenseitig.</b> Wenn eine Person sich in einer Situation sichtlich unwohl fühlt oder gar akut in einer Notsituation ist, dann bieten wir Hilfe an oder stellen diese akuten bereit.</li>  
          <li><b>Jede:r hat das Recht am eigenen Bild.</b> Wir fragen zu Beginn des Seminars nach deiner Einwilligung zur Veröffentlichung von Bilder der Veranstaltung im Internet, solltest du nicht damit einverstanden sein, melde dich gerne in Ruhe in einer Pause bei der Seminarleitung.</li>
          <li><b>Sei pünktlich und aufmerksam während der Vorträge.</b> Zu spät kommen, essen, nur am Handy sein oder schlafen wirft kein gutes Bild auf uns.</li>
        </ol>
        <h2>Code of Conduct der Stiftung</h2>
        <p>Bei der Annahme deines Stipendiums hast du dem Code of Conduct der Stiftung zugestimmt.</p>
        <SOSButton title="Zum Code of Conduct der Stiftung" click={navigateFNFCoC}/>

        {/* <p>Wir stehen für eine Kultur von <b>Respekt und Toleranz</b>.<br/>
        Diskriminierung hat bei uns keinen Platz.<br/>
        <b>Jedes Individuum hat ein Recht auf Entfaltung seiner Persönlichkeit</b>, unabhängig von Merkmalen und gruppenbezogenen Zuschreibungen, insbesondere Geschlecht, sexueller Identität und Orientierung, Abstammung, Hautfarbe und Herkunft, Behinderung, Krankheiten und psychischen Erkrankungen. Dafür treten wir entschlossen ein.</p>

        <p>Um dich besser zu orientieren findest du hier (<b><a href="https://liberale-hochschulgruppen.de/wp-content/uploads/2022/05/Code-of-Conduct.pdf">Download</a></b>) unseren Code of Conduct, in dem du unsere verbandsinternen Regeln zum gegenseitigen Umgang findest:</p>

        <h1>Leitlinien des verbandsinternen Umgangs</h1>

        <SOSCollapse heading="Vielfalt" content={
          <div>
            <SOSImg imgSrc={vielfalt} />
            <p>Jedes Individuum verdient eine individuelle Behandlung. Vielfalt ist für Liberale keine Schwäche, sondern Stärke. Für uns bedeutet Heterogenität immer Ideenvielfalt gepaart mit Lösungschancen. Es ist deswegen unser ureigenstes Anliegen, die Diversität innerhalb des organisierten Liberalismus zu fördern.</p>
            <p>Dieser wertschätzende Umgang mit Vielfalt ist Leitlinie für den Umgang untereinander, für die eigenen Veranstaltungsformate, für die Außenwirkung, für die Ausgestaltung der Öffentlichkeitsarbeit und für die Ansprache von Interessierten.</p>
            <p>So sind mögliche Barrieren für das Engagement zu evaluieren und Lösungsvorschläge zu erarbeiten und zu implementieren. Eintreten für den Liberalismus ist keine Frage des Geldbeutels. Die Auswahl des Orts und die Ausgestaltung der Modalitäten der Veranstaltung soll unter dem Aspekt des Kostenbewusstseins erfolgen. Mitglieder sollen nicht Stigmatisierung fürchten, wenn sie auf und am Rande von Veranstaltungen weniger oder nicht konsumieren. Selbstdarstellungen, die gegen Liberale verwendete Klischees bestärken, sind zu unterlassen.</p>
            <p>Eine andere Form der Barrierefreiheit betrifft den Umgang mit Behinderungen oder anderen Beeinträchtigungen. Inklusion ist soweit als möglich bei allen Veranstaltungen mitzudenken.</p>
            <p>Diskriminierungen in jedweder Form, namentlich Beleidigungen, stereotype Zuschreibungen, Herabwürdigungen, Verächtlichmachen, lassen wir nicht zu. Wir treten diskriminierendem Gedankengut entgegen, auch in codierter Form. Gleiches gilt für die Verherrlichung, Rechtfertigung, Billigung oder Verharmlosung diskriminierender Ideologien oder Regime. Kennzeichen solcher Ideologien oder Regime werden nicht geduldet.</p>
          </div>
        }/>

        <SOSCollapse heading="Respekt" content={
          <div>
            <SOSImg imgSrc={respekt} />
            <p>Offener Diskurs, erfüllendes Engagement, produktive Zusammenarbeit setzen eine Verbandskultur des Respekts im zwischenmenschlichen Umgang voraus. Beleidigungen, Tätlichkeiten, Drohungen und ähnliche Verhaltensweisen vergiften das Verbandsklima und sind nicht zu dulden.</p>
            <p>Liberaler Diskurs zeichnet sich durch Sachargumente, nicht durch persönliche Angriffe aus. Eine Diskussionskultur, die das „Recht des Lauteren“ impliziert, hat bei den Liberalen nichts zu suchen. Bei uns gewinnen nicht die Lautesten und Bedrohlichsten die Diskussion, sondern diejenigen mit den besten Ideen und Argumenten. Die liberale Diskussionskultur lebt vom fairen Streit der Meinungen und ermutigt alle Mitglieder, teilzunehmen.</p>
          </div>
        }/>

        <SOSCollapse heading="Selbstbestimmung" content={
          <div>
            <SOSImg imgSrc={hand} />
            <p>Die Achtung vor den persönlichen Grenzen anderer Individuen ist eine zwingende Ableitung aus dem liberalen Prinzip der Selbstbestimmung und der persönlichen Freiheit. Persönliche Kontakte beruhen auf Freiwilligkeit und der Bundesverband Liberaler Hochschulgruppen ist ein Ort, in dem sich jedes Mitglied wohlfühlen soll und selbstbestimmt entfalten kann. Die Achtung der Selbstbestimmung wird nicht nur auf Veranstaltungen erwartet, sondern auch im privaten Kontakt zwischen Mitgliedern.</p>
            <p>Insbesondere die sexuelle Selbstbestimmung ist ein zentrales Recht, das Liberale entschlossen verteidigen. Die anhaltend hohe Dunkelziffer und die Häufigkeit sexueller Belästigung zeigt die Notwendigkeit einer weiteren und stetigen Sensibilisierung. Dies betrifft bereits die rechtlichen Rahmenbedingungen. So ist die Strafbarkeit unverlangt versandter sexueller Bilder (insbesondere sog. dick pics) nicht durchgängig bekannt. Auch gelten körperliche Belästigungen teils noch als bloße Taktlosigkeit oder gar „temperamentvolles Flirtverhalten“, nicht als Straftat. Schließlich setzt die „Nein heißt Nein“-Regelung gerade nicht voraus, dass sich das Opfer körperlich wehren muss; Handeln gegen den „erkennbaren Willen“ der anderen Person, Ausnutzen einen Überraschungsmomentes oder der Unfähigkeit, einen entgegenstehenden Willen zu äußern oder zu bilden, sind ausreichend, § 177 StGB.</p>
            <p>Konsequent bekämpft gehören auch typische Rechtfertigungsmuster. Sexuelle Bemerkungen oder gar Berührungen sind ohne Konsens keine „Komplimente“, sondern Belästigungen. Dies gilt namentlich für vermeintliche Komplimente für primäre oder sekundäre Geschlechtsmerkmale. Körperliche Distanzlosigkeiten sind stets eine Verletzung des Selbstbestimmungsrechts, es ist keinesfalls eine Rechtfertigung, diese seien ohne sexuelle Intention erfolgt oder jemand solle „sich nicht so haben“, da es eine weniger intime Körperstelle gewesen sei. „Slut shaming“ und „victim blaming“, d.h. der Versuch, Opfern ihrer Kleidung oder Verhaltens wegen eine Mitschuld zu geben oder die Tat sogar zu rechtfertigen, sind zu unterbinden.</p>
            <p>Eine Sensibilisierung muss auch die psychologischen Gründe für geringe Anzeigequoten wie das Schamgefühl der Betroffenen, die Verbreitung typischer Rechtfertigungsmuster und die Bedeutung von Konsens umfassen. Auch sollten aufdringliche Verhaltensweisen wie das wiederholte Sexualisieren von Konversationen ohne zustimmende Reaktion der anderen Person sowie mögliche Machtungleichgewichte reflektiert werden können.</p>
          </div>
        }/>

        <SOSCollapse heading="Führungsverantwortung" content={
          <div>
            <SOSImg imgSrc={umarmung} />
            <p>Führungspersonen kommt für die Verbandskultur eine Schlüsselrolle zu. Ihr Verhalten hat – im Guten wie im Schlechten – Vorbildcharakter. Sie haben für die Erfordernisse einer guten Verbandskultur sensibilisiert zu sein. Führungsverantwortung bewährt sich allerdings nicht in Sonntags- oder Bewerbungsreden, sondern im Ernstfall. Wenn Fehlverhalten vorliegt, bedarf es der Gewissheit, dass alle Führungspersonen rückhaltlos an der Aufklärung und ggf. Ahndung mitwirken. Auch und gerade dann, wenn das Fehlverhalten von anderen Führungspersonen ausgeht. Wer aus Machtkalkül oder aus Angst vor einem politischen Konflikt Machtmissbrauch anderer zulässt, ist für ein Führungsamt ungeeignet. Wenn ein Mitglied den Mut aufbringt, sich einer Führungsperson anzuvertrauen und Fehlverhalten berichtet, ist diese gefordert, Empathie zu zeigen, Handlungsoptionen aufzuzeigen und für eine Aufklärung des Vorfalls zu sorgen. Fatal wäre es, wenn in einer solchen Situation eine Führungsperson den Vorfall selbst herunterspielen würde und das Mitglied demotivieren würde, dagegen vorzugehen.</p>
            <p>Integre Führungspersonen sind sich darüber klar, dass sie eine besondere Verantwortung haben. Sie gehen mit der ihnen überantworteten Macht verantwortungsvoll und benutzen diese nicht für private Interessen oder aus sachfremden Motiven. Sie sind sich bewusst, dass es Mitgliedern schwer fällt, ihnen auszuweichen oder klar Ablehnung zu signalisieren, weil das Machtverhältnis die Kommunikation und Dynamik prägt. Sie achten auf ein angemessenes und respektvolles Auftreten.</p>
            <p>Ein direkter Machtmissbrauch liegt vor, wenn bei Vorhandensein eines Machtungleichgewichts von einem Mitglied Gefälligkeiten eingefordert werden. Dabei spielt es keine Rolle, ob diese im Austausch für eine positiv formulierte Gegenleistung (Förderung der politischen Karriere, Versprechen eines Amtes etc.) oder zur Abwendung eines Übels („sonst wirst du nichts mehr im Verband“) gefordert werden.</p>
            <p>Besonders schwerwiegend ist die Ausnutzung eines Machtungleichgewichts in einem direkten Subordinationsverhältnis, bspw. im Verhältnis eines Vorsitzenden zu einem anderen Vorstandsmitglied oder eines Vorstandsmitglieds zu einem Mitglied.</p>
            <p>Stark hierarchische Organisationen sind besonders anfällig für Machtmissbrauch; der Täter bekommt oftmals das Gefühl, er könne sich alles erlauben, komme mit allem durch. Dritte schreiten aus Angst vor Konsequenzen oder aus Machtkalkül gleichfalls nicht ein. Hier ist eine Sensibilisierung dahingehend nötig, dass hohe Amtsträger nicht als sakrosankt gelten, und dass das anzeigende Mitglied nicht als „Nestbeschmutzer“ und „Unruhestifter“ gilt. Das ist besonders wichtig, weil es einen psychologischen Bias dahingehend gibt, einer Person zu vertrauen, die man selbst in ein (hohes) Amt gewählt hat, und das Eingeständnis, dass sie ihr Amt missbraucht hat, menschlich verständlich schwer fällt. Besonders sexuelle Übergriffe erfolgen oft aus einer Situation der Macht und Machtlosigkeit heraus; sie dienen dem Täter der Demonstration von Stärke. Hier ist Sensibilisierungsarbeit zu betreiben.</p>
          </div>
        }/>

        <SOSCollapse heading="Institutionelle Sicherungen" content={
          <div>
            <SOSImg imgSrc={schiedsgericht} />
            <p>Der Bundesverband Liberaler Hochschulgruppen hat durch die Einführung der Bundesombudsperson eine wichtige Institution für die Sicherung einer guten Verbandskultur geschaffen. Die Bundesombudsperson ist Ansprechperson für alle Mitglieder und Mitgliedsgruppen. Zusätzlich zur Bundesombudsperson sind bei Veranstaltungen auf Bundesebene Vertrauenspersonen etabliert worden. Für diese hat der AK Diversity Leitfäden bereitgestellt.</p>
            <p>Es ist wünschenswert, wenn alle LHG-Landesverbände Ombudspersonen etablieren. Anzustreben ist eine Vernetzung der Bundesombudsperson mit den Landesombudspersonen.</p>
          </div>
        }/>

        <br/>
        <h1>Reaktion auf Fehlverhalten</h1>

        <SOSCollapse heading="Ansprache von Fehlverhalten" content={
          <div>
            <SOSImg imgSrc={lavo} />
            <p>Eine gute Verbandskultur setzt die Bereitschaft aller voraus, sie im alltäglichen Umgang vorzuleben. Dazu gehört nicht nur der Verzicht auf eigenes Fehlverhalten, sondern auch, dass Fehlverhalten nicht unwidersprochen wird. Betroffene von Diskriminierung werden unterstützt, sich vor sie gestellt und ihnen Wege aufgezeigt, das Fehlverhalten zu melden. Wer diskriminiert, muss mit deutlichem Widerspruch konfrontiert werden und sollte darüber hinaus mit dem Ziel der Sensibilisierung angesprochen werden. Betroffene sollten ermutigt werden, ihr Unwohlsein auszudrücken und klare Grenzen aufzuzeigen, statt belästigendes Verhalten verlegen hinzunehmen. Relativierenden, banalisierenden oder rechtfertigenden Kommentaren ist ebenso entgegen zu treten. Ansprache von Fehlverhalten ist Zivilcourage, die von jedem Mitglied erwartet wird. Für Mitglieder mit Führungsverantwortung ist die Bereitschaft hierzu wesentlicher Teil der charakterlichen Eignung für ein Amt.</p>
          </div>
        }/>

        <SOSCollapse heading="Aufklärung von Fehlverhalten" content={
          <div>
            <SOSImg imgSrc={sprechen} />
            <p>Fehlverhalten kann durch eine aufmerksame Verbandskultur oft vorgebeugt werden. Umgekehrt können von Wegschauen, stillschweigender Hinnahme oder gar gegenseitiger Deckung gekennzeichnete Strukturen Fehlverhalten ermöglichen und entscheidend dazu beitragen, dass es unerkannt und ungeahndet bleibt. Solche Strukturen schüchtern Betroffene ein, bewirken eine sinkende Bereitschaft, Fehlverhalten anzusprechen und zu melden und verhindern selbst bei Anzeige eine effektive Durchsetzung des Schutzes vor Diskriminierung und Belästigung. So entstehen Schweigespiralen, die Betroffenen es immer schwerer machen, den Mut aufzubringen, Fehlverhalten anzusprechen.</p>
            <p>Wer versucht, Fehlverhalten zu vertuschen, Ermittlungen zu behindern oder die Ahndung zu vereiteln, begeht selbst ein Fehlverhalten. Dazu gehören namentlich das Vernichten oder Verbergen von Daten und anderen Beweismitteln sowie der Versuch, auf Betroffene, Zeugen, Vorstandsmitglieder und Ombuds- und Vertrauenspersonen einzuwirken.</p>
            <p>Jede Person mit Führungsverantwortung ist, wenn sie zureichende tatsächliche Anhaltspunkte für ein Fehlverhalten hat, verpflichtet, diesen nachzugehen oder sich direkt an eine zuständige Ombudsperson zu wenden; sie hat Untersuchungen nach Kräften zu unterstützen und Erkenntnisse offenzulegen.</p>
          </div>
        }/>

        <SOSCollapse heading="Ahndung von Fehlverhalten" content={
          <div>
            <SOSImg imgSrc={nein} />
            <p>Für Diskriminierung und Belästigung gilt eine Nulltoleranzpolitik. Jedes Fehlverhalten zieht eine Reaktion nach sich. Hierzu gehören auch die Ansprache des Fehlverhaltens und ein Gespräch mit dem Verantwortlichem zum Zwecke der Sensibilisierung. Bei gravierendem Fehlverhalten auf Veranstaltungen kann als Maßnahme der Gefahrenabwehr ein Ausschluss von der Veranstaltung unter Berufung auf das Hausrecht gerechtfertigt sein.</p>
            <p>Wegen schwerwiegenden Fehlverhaltens wird ein Ordnungsverfahren nach den Vorschriften der Satzung und der Schiedsordnung eingeleitet. Die verbandsinternen Ordnungsverfahren können Fehlverhalten betreffen, welches strafrechtlich relevant ist. Soweit der Bundesverband selbst Geschädigter ist, zeigt er das Fehlverhalten an. Mitglieder, welche eine solche Tat bei den zuständigen Stellen melden, sind zu beraten und auf die zusätzliche Möglichkeit einer strafrechtlichen Verfolgung hinzuweisen, für deren Vorbereitung Unterstützung angeboten werden kann. Straf- und Ordnungsverfahren verfolgen unterschiedliche Zwecke. Sie sind daher unabhängig voneinander möglich und können auch parallel betrieben werden.</p>
          </div>
        }/> */}

        <br/>
      </div>
      <footer className="SOS-footer">
        <SOSLinkButton title="Zurück nach oben" click={scrollUp}/>
      </footer>
    </div>
  );
}
