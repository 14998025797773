import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import SOSImg from '../../components/sosimg';
import SOSButton from '../../components/sosbutton';
import SOSSelect from '../../components/sosselect';
import SOSLargeButton from '../../components/soslargebutton';
import SOSLinkButton from '../../components/soslinkbutton';
import lavo from '../../icons/lavo.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);
  const [bundesland, setBundesland] = useState('');
  const [landesverband, setLandesverband] = useState('');
  const [landesverbandKontaktUrl, setLandesverbandKontaktUrl] = useState('');

  const bundeslaender = ['Baden-Württemberg', 'Bayern', 'Berlin-Brandenburg', 'Bremen', 'Hamburg', 'Hessen', 'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen', 'Rheinland-Pfalz', 'Saarland', 'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen']

  const navigateBack = () => {
    navigate(-1);
  }

  const navigateExternal = (url) => {
    window.location.assign(url);
  };

  const bundeslaenderSelect = (e) => {
    setBundesland(e.target.options[e.target.selectedIndex].text);
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  useEffect(() => {
    switch (bundesland) {
      case '':
        break;
      case 'Baden-Württemberg':
        setLandesverband('LHG Baden-Württemberg');
        setLandesverbandKontaktUrl('mailto:bw@bundes-lhg.de');
        break;
      case 'Bayern':
        setLandesverband('LHG Bayern');
        setLandesverbandKontaktUrl('mailto:by@bundes-lhg.de');
        break;
      case 'Berlin-Brandenburg':
        setLandesverband('LHG Berlin-Brandenburg');
        setLandesverbandKontaktUrl('mailto:bb@bundes-lhg.de');
        break;
      case 'Bremen':
        setLandesverband('LHG Bremen');
        setLandesverbandKontaktUrl('mailto:hb@bundes-lhg.de');
        break;
      case 'Hamburg':
        setLandesverband('LHG Hamburg');
        setLandesverbandKontaktUrl('mailto:hh@bundes-lhg.de');
        break;
      case 'Hessen':
        setLandesverband('LHG Hessen');
        setLandesverbandKontaktUrl('mailto:he@bundes-lhg.de');
        break;
      case 'Mecklenburg-Vorpommern':
        setLandesverband('LHG Mecklenburg-Vorpommern');
        setLandesverbandKontaktUrl('mv@bundes-lhg.de');
        break;
      case 'Niedersachsen':
        setLandesverband('LHG Niedersachsen');
        setLandesverbandKontaktUrl('mailto:ni@bundes-lhg.de');
        break;
      case 'Nordrhein-Westfalen':
        setLandesverband('LHG NRW');
        setLandesverbandKontaktUrl('mailto:nrw@bundes-lhg.de');
        break;
      case 'Rheinland-Pfalz':
        setLandesverband('LHG Rheinland-Pfalz');
        setLandesverbandKontaktUrl('rp@bundes-lhg.de');
        break;
      case 'Saarland':
        setLandesverband('LHG Saar');
        setLandesverbandKontaktUrl('sl@bundes-lhg.de');
        break;
      case 'Sachsen':
        setLandesverband('LHG Sachsen');
        setLandesverbandKontaktUrl('mailto:sn@bundes-lhg.de');
        break;
      case 'Sachsen-Anhalt':
        setLandesverband('LHG Sachsen-Anhalt');
        setLandesverbandKontaktUrl('st@bundes-lhg.de');
        break;
      case 'Schleswig-Holstein':
        setLandesverband('LHG Schleswig-Holstein');
        setLandesverbandKontaktUrl('sh@bundes-lhg.de');
        break;
      case 'Thüringen':
        setLandesverband('LHG Thüringen');
        setLandesverbandKontaktUrl('th@bundes-lhg.de');
        break;
      default:
        setLandesverband('');
        setLandesverbandKontaktUrl('');
    }
  }, [bundesland])

  const navigateASP = () => {
    window.location.assign('https://extranet.freiheit.org/display/Suche');
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Ansprechpartner am Hochschulort (ASP)</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={lavo} />
        <p>Dein <b>Ansprechpartner am Hochschulort</b> kann dir bei Anliegen rund um deinen Hochschulort weiterhelfen. Filtere in der Personensuche im Extranet einfach nach "Ansprechpartnerinnen und -partner am Hochschulort (ASP)".</p>
        {/* <SOSSelect title="Wähle dein Bundesland" values={bundeslaender} change={bundeslaenderSelect}/>
        <br />
        <SOSLargeButton title={landesverband} subtitle={landesverbandKontaktUrl !== '' ? 'E-Mail-Kontakt' : 'Leider keine Kontaktdaten bekannt :('} click={() => navigateExternal(landesverbandKontaktUrl)} addClass={landesverband === '' ? 'hidden' : ''}/> */}
        <SOSButton title="Zu den ASPs im Extranet" click={navigateASP}/>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
