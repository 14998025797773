import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import gelbesherz from '../../icons/gelbes-herz.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate('../assistenz?restore=true');
  }
  const navigateOmbudsmitglied = () => {
    navigate('/kontakt/ombudsperson')
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Assistenz</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <h1>Gibt es einen Konflikt?</h1>
        <p>Mach dir keine Sorgen, das kann immer einmal vorkommen. Vielleicht hilft es dir bereits etwas, einmal in Ruhe und sachlich mit der Person zu sprechen und darzulegen, was zum Konflikt geführt hat.</p>
        <p>Du kannst dich natürlich auch jederzeit an das Bundesombudsmitglied wenden - auch anonym. Dieses hilft gerne vertraulich weiter und wird sich bemühen, mit dir die Ursachen des Konfliktes zu ermitteln und diesen zu lösen.</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="Ombudsmitglied" imgSrc={gelbesherz} click={navigateOmbudsmitglied}/>
        </div>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
