import './components.css';
import { useState, useEffect } from "react";
import axios from "axios";
import SOSSelect from './sosselect.js';
import SOSLargeImgButton from './soslargeimgbutton.js';
import engel from '../icons/engel.png';


export default function SOSOrgaPersonDropdown({addClass ='', title, items = []}) {
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [orgaPersons, setOrgaPersons] = useState([]);
  const [orgaPersonsCode, setOrgaPersonsCode] = useState();
  const [eventNames, setEventNames] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var arr = [];
    items.forEach((el, i) => {
      arr[i] = el[0];
    });
    setEventNames(arr);
  }, [items])

  //call number
  const callNumber = number => {
    window.location.assign("tel:" + number.replace(/\s/g,''));
  }

  //set selected event
  const eventSelect = (e) => {
    setSelectedEvent(items[e.target.selectedIndex - 1]);
  }

  //get orga persons from server when event is selected
  useEffect(() => {
    setOrgaPersons([]);

    if (selectedEvent.length > 0 && selectedEvent[2].length > 0) {
      setLoading(true);

      axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_orga_persons&orga_persons=' + selectedEvent[3]) //code to ./
      .then(response => {
        setOrgaPersons(response.data);
        setLoading(false);
      })
      .catch( (error) => {
        setLoading(false);
        console.log(error);
      });
    }
  }, [selectedEvent])

  //set orga persons when result is received
  useEffect(() => {
    if (orgaPersons.length > 0) {
      setOrgaPersonsCode(orgaPersons.map((person, i) => <SOSLargeImgButton key={i} title={person[1]} subtitle={person[2]} click={() => callNumber(person[2])} imgSrc={engel} url={person[3] !== '' ? window.location.origin + '/' + person[3] : ''}/>));
    } else if (selectedEvent.length !== 0) {
      setOrgaPersonsCode(<p>Leider wurden keine Organisatoren gefunden. Kontaktiere doch am Besten das Ombudsmitglied.</p>);
    }
  }, [orgaPersons])

  return (
    <div className="SOS-search-input SOS-orga-person-search">
      <SOSSelect title={title} values={eventNames} change={eventSelect}/>

      <div className={loading ? 'loading-round SOS-orga-person-search-event' : 'SOS-orga-person-search-event'} >
        <div className="SOS-content-grid">
          {orgaPersonsCode}
        </div>
      </div>
    </div>
  );
}
