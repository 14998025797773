import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import axios from "axios";
import parse from "html-react-parser";
import SOSImg from '../../components/sosimg';
import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import gelbesherz from '../../icons/gelbes-herz.png';
import telefon from '../../icons/telefon.png';
import email from '../../icons/email.png';
import schloss from '../../icons/schloss.png';


export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);
  const [ombud1Name, setOmbud1Name] = useState('');
  const [ombud1Image, setOmbud1Image] = useState('');
  const [ombud1Mail, setOmbud1Mail] = useState('');
  const [ombud1Phone, setOmbud1Phone] = useState('');
  const [ombud2Name, setOmbud2Name] = useState('');
  const [ombud2Image, setOmbud2Image] = useState('');
  const [ombud2Mail, setOmbud2Mail] = useState('');
  const [ombud2Phone, setOmbud2Phone] = useState('');

  useEffect(() => {
    //get ombud data
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson1_name') //code to ./
    .then(response => {
      setOmbud1Name(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson1_bild') //code to ./
    .then(response => {
      setOmbud1Image(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson1_mail') //code to ./
    .then(response => {
      setOmbud1Mail(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson1_phone') //code to ./
    .then(response => {
      setOmbud1Phone(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson2_name') //code to ./
    .then(response => {
      setOmbud2Name(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson2_bild') //code to ./
    .then(response => {
      setOmbud2Image(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson2_mail') //code to ./
    .then(response => {
      setOmbud2Mail(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson2_phone') //code to ./
    .then(response => {
      setOmbud2Phone(response.data[0][0]);
    });
  }, []);

  const navigateBack = () => {
    navigate(-1);
  }

  const navigateAnonymerKontakt = () => {
    navigate('../kontakt/anonymer-kontakt');
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const callOmbud1 = () => {
    window.location.assign("tel:" + ombud1Phone);
  }

  const callOmbud2 = () => {
    window.location.assign("tel:" + ombud2Phone);
  }

  const mailOmbud1 = () => {
    const anrede = 'Hallo%20' + ombud1Name;
    window.location.assign("mailto:" + ombud1Mail + "?subject=Ich%20ben%C3%B6tige%20deine%20Unterst%C3%BCtzung%20%7C%20SOS-App&body=" + anrede + "%2C%0A%0Aich%20ben%C3%B6tige%20deine%20Unterst%C3%BCtzung%2C%20hier%20ist%20mein%20Anliegen%3A%0A%0A");
  }

  const mailOmbud2 = () => {
    const anrede = 'Hallo%20' + ombud2Name;
    window.location.assign("mailto:" + ombud2Mail + "?subject=Ich%20ben%C3%B6tige%20deine%20Unterst%C3%BCtzung%20%7C%20SOS-App&body=" + anrede + "%2C%0A%0Aich%20ben%C3%B6tige%20deine%20Unterst%C3%BCtzung%2C%20hier%20ist%20mein%20Anliegen%3A%0A%0A");
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Vertrauenspersonen</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <p>Hallo, wir sind die Vertrauenspersonen der Stipendiat:innenschaft und jederzeit für dich da. <br/>Selbstverständlich werden alle Anliegen diskret behandelt.</p>
        <h2>Du kannst dich gerne persönlich oder anonym an uns wenden: </h2>
        <SOSImg imgSrc={gelbesherz} url={ombud1Image} />
        <h1>{parse(ombud1Name)}</h1>
        <br/>
        <div className="SOS-content-grid">
          <SOSImgButton title="Anrufen" imgSrc={telefon} click={callOmbud1}/>
          <SOSImgButton title="E-Mail" imgSrc={email} click={mailOmbud1}/>
          {/* <SOSImgButton title="Anonymer Kontakt" imgSrc={schloss} click={navigateAnonymerKontakt}/> */}
        </div>
        <SOSImg imgSrc={gelbesherz} url={ombud2Image} />
        <h1>{parse(ombud2Name)}</h1>
        <br/>
        <div className="SOS-content-grid">
          <SOSImgButton title="Anrufen" imgSrc={telefon} click={callOmbud2}/>
          <SOSImgButton title="E-Mail" imgSrc={email} click={mailOmbud2}/>
          {/* <SOSImgButton title="Anonymer Kontakt" imgSrc={schloss} click={navigateAnonymerKontakt}/> */}
        </div>

      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
