import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import SOSImg from '../../components/sosimg';
import SOSButton from '../../components/sosbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import buvo from '../../icons/buvo.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  /* useEffect(() => {
    window.location.assign('http://www.stipendiat.org/sprecher-innen/');
  }, []); */

  const navigateBack = () => {
    navigate(-1);
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const navigateBuVo = () => {
    window.location.assign('http://www.stipendiat.org/sprecher-innen/');
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Sprecher:innen</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={buvo} />
        <p>Die <b>Sprecher:innen</b> sind das gewählte Vertretungsgremium der Stipendiat:innen und werden jährlich beim Konvent gewählt. Die Mitglieder der Sprecher:innen sind immer gerne für deine Fragen und Anliegen da. Wir leiten dich auf unsere Webseite weiter, dort findest du alle Mitglieder.</p>
        <SOSButton title="Zu den Sprecher:innen" click={navigateBuVo}/>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
