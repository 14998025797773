import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import sos from '../../icons/sos.png';
import vielfalt from '../../icons/vielfalt.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate('../assistenz?restore=true');
  }
  const navigateNotruf = () => {
    navigate('/Emergency-Call')
  }
  const navigateVertrauenspersonen = () => {
    navigate('/kontakt/vertrauenspersonen')
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="Stip-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Assistenz</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <h1>Ein Notfall?</h1>
        <p>Wähle bitte bei lebensbedrohlichen Notfällen sofort den Notruf. </p>
        <p>Wenn du dir unsicher bist kannst du dich auch an die Vertrauenspersonen wenden.</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="NOTRUF" imgSrc={sos} click={navigateNotruf}/>
          <SOSImgButton title={<span>Vertrauens<wbr/>&shy;personen</span>} imgSrc={vielfalt} click={navigateVertrauenspersonen}/>
        </div>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
