import './components.css';

export default function SOSLargeImgButton({addClass = '', title, subtitle, imgSrc, click, url = ''}) {
  var style;

  if (url !== '') {
    style = {
      img: {
        backgroundImage: 'url(' + url + ')',
        backgroundSize: 'cover',
      }
    }
  } else {
    style = {
      img: {
        backgroundImage: `url(${imgSrc})`
      }
    }
  }

  return (
    <button className={"SOS-img-button SOS-large-img-button " + addClass} onClick={click} title={title}>
      <div className="SOS-img-button-icon" style={style.img}>&nbsp;</div>
      <p>{title}<br/>
      <span className="SOS-large-img-button-subtitle">{subtitle}</span></p>
    </button>
  );
}
